// Warning! Any code placed here will be ignored by bots

(function(window, $) {
  $(document).delegate(".et-ac-suf-bo.handle", "click", function(event) {
    var place = $(this).data("place");
    var path = atob(place);
    if ($(this).attr('data-query')){
      var query = $(this).data("query");
      location.href = path + '?' + query;
    } else {
      location.href = path;
    }
    return false;
  });
})(window, jQuery);
